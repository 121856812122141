import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { AkitaCheckoutState, createInitialCheckoutState } from '../models/checkout.state';
import { SessionModel } from '../models/session.model';
import { PaymentConfigurationModel } from '../models/payment-configuration.model';
import { PaymentOrderModel } from '../models/payment-order.model';
import { PaymentModel } from '../models/payment.model';
import { DiscountInfo } from '@app/akita/router/models/discount-info.model';
import { UserDetails } from '../../addresses/models/user-details.model';
import { PaymentMethod, PaymentProvider } from '../models/payment.provider.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'checkout', resettable: true })
export class AkitaCheckoutStore extends Store<AkitaCheckoutState> {
  constructor() {
    super(createInitialCheckoutState());
  }

  public setSession(session?: SessionModel | null): void {
    this.update({
      session: session,
    });
  }

  public setSessionUserDetails(userDetails: UserDetails): void {
    const session = this.getValue().session || new SessionModel();
    session.userName = userDetails.fullName;
    session.email = userDetails.email;
    session.phone = userDetails.contactDetails;
    session.shippingAddress = userDetails.address;
    session.billingAddress = userDetails.address;
    this.update({
      session: session || null,
    });
  }

  public toggleFetchingSession(fetching?: boolean | null): void {
    this.update({
      fetchingSession: Boolean(fetching),
    });
  }

  public setErrorFetchingSession(error?: ApiError | null): void {
    this.update({
      errorFetchingSession: error || null,
    });
  }

  public setOrder(order?: PaymentOrderModel | null): void {
    this.update({
      order: order || null,
    });
  }

  public setUserOrders(orders?: Array<PaymentOrderModel> | null): void {
    this.update({
      userOrders: orders || null,
    });
  }

  public setGotDiscountAfterLocked(value?: boolean | null): void {
    this.update({
      gotDiscountAfterLocked: Boolean(value),
    });
  }

  public setOrderId(id: string | null): void {
    this.update({
      orderId: id || null,
    });
  }

  public setPaymentId(id: string | null): void {
    this.update({
      paymentId: id || null,
    });
  }

  public setPaymentProvider(provider: PaymentProvider): void {
    const session = this.getValue().session || new SessionModel();
    session.paymentProvider = provider;
    this.update({
      session: session || null,
    });
  }

  public setPaymentMethod(method: PaymentMethod): void {
    const session = this.getValue().session || new SessionModel();
    session.paymentMethod = method;
    this.update({
      session: session || null,
    });
  }

  public setDiscountCode(discount: DiscountInfo | null): void {
    const session = this.getValue().session || new SessionModel();
    session.discount = discount;
    this.update({
      session: session || null,
    });
  }

  public toggleFetchingOrder(fetching?: boolean | null): void {
    this.update({
      fetchingOrder: Boolean(fetching),
    });
  }

  public setErrorFetchingOrder(error?: ApiError | null): void {
    this.update({
      errorFetchingOrder: error || null,
    });
  }

  public toggleUpdatingOrder(updating?: boolean | null): void {
    this.update({
      updatingOrder: Boolean(updating),
    });
  }

  public setErrorUpdatingOrder(error?: ApiError | null): void {
    this.update({
      errorUpdatingOrder: error || null,
    });
  }

  public setPayment(payment?: PaymentModel | null): void {
    this.update({
      payment: payment || null,
    });
  }

  public toggleFetchingPayment(fetching?: boolean | null): void {
    this.update({
      fetchingPayment: Boolean(fetching),
    });
  }

  public setErrorFetchingPayment(error?: ApiError | null): void {
    this.update({
      errorFetchingPayment: error || null,
    });
  }

  public toggleUpdatingPayment(updating?: boolean | null): void {
    this.update({
      updatingPayment: Boolean(updating),
    });
  }

  public setErrorUpdatingPayment(error?: ApiError | null): void {
    this.update({
      errorUpdatingPayment: error || null,
    });
  }

  public setConfigurations(
    configurations?: Array<PaymentConfigurationModel> | null
  ): void {
    this.update({
      configurations: configurations || null,
    });
  }

  public toggleFetchingConfigurations(fetching?: boolean | null): void {
    this.update({
      fetchingConfigurations: Boolean(fetching),
    });
  }

  public setErrorFetchingConfigurations(error?: ApiError | null): void {
    this.update({
      erroFetchingConfigurations: error || null,
    });
  }

  public setCKOPaymentToken(id: string | null): void {
    this.update({
      ckoPaymentToken: id || null,
    });
  }

  public setCKOCardHolderName(name: string | null): void {
    this.update({
      ckoCardHolderName: name || null,
    });
  }

  public setIsSubmitting(isSubmitting?: boolean | null): void {
    this.update({
      isSubmitting: Boolean(isSubmitting),
    });
  }

  public setShowTabbyPromo(show?: boolean | null): void {
    this.update({
      showTabbyPromo: Boolean(show),
    });
  }

  public setShowQuaraPromo(show?: boolean | null): void {
    this.update({
      showQuaraPromo: Boolean(show),
    });
  }

  public setShowBaseetaPromo(show?: boolean | null): void {
    this.update({
      showBaseetaPromo: Boolean(show),
    });
  }

  public setShowTamaraPromo(show?: boolean | null): void {
    this.update({
      showTamaraPromo: Boolean(show),
    });
  }

  public setShowApplePay(show?: boolean | null): void {
    this.update({
      showApplePay: Boolean(show),
    });
  }

  public setShowGooglePay(show?: boolean | null): void {
    this.update({
      showGooglePay: Boolean(show),
    });
  }

  public setIsRiskJsInitialized(isInitialized?: boolean | null): void {
    this.update({
      isRiskJsInitialized: Boolean(isInitialized),
    });
  }

  public setIsRiskJsDataPublished(isPublished?: boolean | null): void {
    this.update({
      isRiskJsDataPublished: Boolean(isPublished),
    });
  }

  public setRiskJsDeviceSessionId(id: string | null): void {
    this.update({
      riskJsDeviceSessionId: id || null,
    });
  }

  public setErrorStcPayment(error?: ApiError | null): void {
    this.update({
      errorStcPayment: error || null,
    });
  }

  public toggleIsPlacingOrder(isPlacingOrder?: boolean | null): void {
    this.update({
      isPlacingOrder: Boolean(isPlacingOrder),
    });
  }

  public setIsExpressCheckoutOrder = (isExpressCheckoutOrder: boolean): void => {
    this.update({
      isExpressCheckoutOrder,
    });
  };
}
