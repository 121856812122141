import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { Query } from '@datorama/akita';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { SessionModel } from '../models/session.model';
import { AkitaCheckoutState, CheckoutStep } from '../models/checkout.state';
import { AkitaCheckoutStore } from './checkout.store';
import { PaymentConfigurationModel } from '../models/payment-configuration.model';
import { PaymentRequestInfo } from '../models/payment-request-info.model';
import { getCurrentHost } from '@app/shared/utils/url.utils';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { PriceModel } from '@app/shared/models/api/price.model';
import { PaymentOrderModel } from '../models/payment-order.model';
import { DiscountInfo } from '@app/akita/router/models/discount-info.model';
import { PaymentMethod, PaymentProvider } from '../models/payment.provider.model';
import { PaymentModel } from '../models/payment.model';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

@Injectable({ providedIn: 'root' })
export class AkitaCheckoutQuery extends Query<AkitaCheckoutState> {
  constructor(
    protected readonly store: AkitaCheckoutStore,
    private readonly akitaAuthQuery: AkitaAuthQuery,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    super(store);
  }

  public get sessionId(): string | null {
    return this.getValue().session?.id || null;
  }

  public get session(): SessionModel | null {
    return this.getValue().session || null;
  }

  public get order(): PaymentOrderModel | null {
    return this.getValue().order || null;
  }

  public get orderId(): string | null {
    return this.getValue().orderId || null;
  }

  public get paymentId(): string | null {
    return this.getValue().paymentId || null;
  }

  public get paymentProvider(): PaymentProvider | null {
    return this.getValue().paymentProvider;
  }

  public get paymentMethod(): string | null {
    return this.getValue().paymentMethod;
  }

  public get step(): CheckoutStep {
    return this.getValue().step;
  }

  public get isSubmitting(): boolean {
    return Boolean(this.getValue().isSubmitting);
  }

  public get discount(): DiscountInfo | null {
    return this.getValue().session?.discount || null;
  }

  public get fetchingSession(): boolean {
    return Boolean(this.getValue().fetchingSession);
  }

  public get fetchingPayment(): boolean {
    return Boolean(this.getValue().fetchingPayment);
  }

  public get fetchingConfigurations(): boolean {
    return Boolean(this.getValue().fetchingConfigurations);
  }

  public get gotDiscountAfterLocked(): boolean {
    return Boolean(this.getValue().gotDiscountAfterLocked);
  }

  public get fetchingOrder(): boolean {
    return Boolean(this.getValue().fetchingOrder);
  }

  public getSession(): SessionModel | null {
    return this.getValue().session || null;
  }

  public getErrorStcPayment(): ApiError | null {
    return this.getValue().errorStcPayment || null;
  }

  public get isErrorStcPayment(): boolean {
    return Boolean(this.getValue().errorStcPayment?.code);
  }

  public selectSessionPaymentProvider(): Observable<PaymentProvider | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.paymentProvider || null),
      distinctUntilChanged()
    );
  }

  public selectSessionPaymentMethod(): Observable<PaymentMethod | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.paymentMethod || null),
      distinctUntilChanged()
    );
  }

  public selectUserOrders(): Observable<Array<PaymentOrderModel> | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.userOrders),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFetchingSession(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => Boolean(state.fetchingSession)),
      distinctUntilChanged()
    );
  }

  public selectFetchingOrder(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => Boolean(state.fetchingOrder)),
      distinctUntilChanged()
    );
  }

  public selectErrorFetchingSession(): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.errorFetchingSession),
      distinctUntilChanged()
    );
  }

  public selectErrorFetchingOrder(): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.errorFetchingOrder),
      distinctUntilChanged()
    );
  }

  public selectErrorUpdatingPayment(): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.errorUpdatingPayment),
      distinctUntilChanged()
    );
  }

  public selectErrorFetchingPayment(): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.errorFetchingPayment),
      distinctUntilChanged()
    );
  }

  public selectSession(): Observable<SessionModel | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectOrder(): Observable<PaymentOrderModel | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.order),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectStoreCurrency(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.storeCurrency || null),
      distinctUntilChanged()
    );
  }

  public selectStoreCountry(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.storeCountry || null),
      distinctUntilChanged()
    );
  }

  public selectTotalPrice(): Observable<PriceModel | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.totalPrice || null),
      distinctUntilChanged()
    );
  }

  public selectPaymentConfigurations(): Observable<Array<PaymentConfigurationModel> | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.configurations),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsPlacingOrder(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => Boolean(state.isPlacingOrder)),
      distinctUntilChanged()
    );
  }

  public selectCheckoutStep(): Observable<CheckoutStep> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.step),
      distinctUntilChanged()
    );
  }

  public selectShowTabbyPromo(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showTabbyPromo),
      distinctUntilChanged()
    );
  }

  public selectShowQuaraPromo(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showQuaraPromo),
      distinctUntilChanged()
    );
  }

  public selectShowBaseetaPromo(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showBaseetaPromo),
      distinctUntilChanged()
    );
  }

  public selectShowTamaraPromo(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showTamaraPromo),
      distinctUntilChanged()
    );
  }

  public selectShowApplePay(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showApplePay),
      distinctUntilChanged()
    );
  }

  public selectShowGooglePay(): Observable<boolean | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.showGooglePay),
      distinctUntilChanged()
    );
  }

  public selectIsSubmitting(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.isSubmitting),
      distinctUntilChanged()
    );
  }

  public selectGotDiscountAfterLocked(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.gotDiscountAfterLocked),
      distinctUntilChanged()
    );
  }

  public selectProtectionPrice(): Observable<PriceModel | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.session?.protectionPrice || null),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectPayment(): Observable<PaymentModel | null> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.payment),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsExpressCheckoutOrder(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaCheckoutState) => state.isExpressCheckoutOrder),
      distinctUntilChanged()
    );
  }

  public getStripeV2PaymentDetails(): PaymentRequestInfo {
    return {
      session_id: this.sessionId || '',
      customer_id: null, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_hash: 'string | null',
      card_token: 'string | null',
      wallet: null,
      post_action_links: null,
      card_holder: null,
    };
  }

  public getTabbyPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let tabbySuccessUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tabby-success`;
    let tabbyCancelUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tabby-cancel`;
    let tabbyFailureUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tabby-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      tabbySuccessUrl = `${tabbySuccessUrl}?${queryParams}`;
      tabbyCancelUrl = `${tabbyCancelUrl}?${queryParams}`;
      tabbyFailureUrl = `${tabbyFailureUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: tabbySuccessUrl,
        on_fail: tabbyFailureUrl,
        on_cancel: tabbyCancelUrl,
      },
    };
  }

  public getTamaraPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;

    let tabbySuccessUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tamara-success`;
    let tabbyCancelUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tamara-cancel`;
    let tabbyFailureUrl = `${host}${localeFragment}/checkout/${this.sessionId}/tamara-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      tabbySuccessUrl = `${tabbySuccessUrl}?${queryParams}`;
      tabbyCancelUrl = `${tabbyCancelUrl}?${queryParams}`;
      tabbyFailureUrl = `${tabbyFailureUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: tabbySuccessUrl,
        on_fail: tabbyFailureUrl,
        on_cancel: tabbyCancelUrl,
      },
    };
  }

  public getPayPalPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let tabbySuccessUrl = `${host}${localeFragment}/checkout/${this.sessionId}/paypal-success`;
    let tabbyCancelUrl = `${host}${localeFragment}/checkout/${this.sessionId}/paypal-cancel`;
    let tabbyFailureUrl = `${host}${localeFragment}/checkout/${this.sessionId}/paypal-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      tabbySuccessUrl = `${tabbySuccessUrl}?${queryParams}`;
      tabbyCancelUrl = `${tabbyCancelUrl}?${queryParams}`;
      tabbyFailureUrl = `${tabbyFailureUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: tabbySuccessUrl,
        on_fail: tabbyFailureUrl,
        on_cancel: tabbyCancelUrl,
      },
    };
  }

  public getQuaraPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let tabbySuccessUrl = `${host}${localeFragment}/checkout/${this.sessionId}/quara-success`;
    let tabbyCancelUrl = `${host}${localeFragment}/checkout/${this.sessionId}/quara-cancel`;
    let tabbyFailureUrl = `${host}${localeFragment}/checkout/${this.sessionId}/quara-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      tabbySuccessUrl = `${tabbySuccessUrl}?${queryParams}`;
      tabbyCancelUrl = `${tabbyCancelUrl}?${queryParams}`;
      tabbyFailureUrl = `${tabbyFailureUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: tabbySuccessUrl,
        on_fail: tabbyFailureUrl,
        on_cancel: tabbyCancelUrl,
      },
    };
  }

  public getCheckoutLtdPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_hash: null,
      card_token: this.getValue().ckoPaymentToken || null,
      device_session_id: this.getValue().riskJsDeviceSessionId || null,
      wallet: null,
      card_holder: {
        customer_details: {
          name: this.getValue().ckoCardHolderName || this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public getBaseetaPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/baseeta-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/baseeta-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_hash: null,
      card_token: this.getValue().ckoPaymentToken || null,
      device_session_id: this.getValue().riskJsDeviceSessionId || null,
      wallet: null,
      card_holder: {
        customer_details: {
          name: this.getValue().ckoCardHolderName || this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public getApplePayCheckoutLtdPaymentDetails(
    queryParams?: string | null
  ): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    // push express checkout flag to the query params for the success purchase event
    if (this.getValue().isExpressCheckoutOrder === true) {
      queryParams = queryParams
        ? `${queryParams}&express_checkout=true`
        : '?express_checkout=true';
      this.store.setIsExpressCheckoutOrder(false);
    }

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      wallet: {
        apple_pay_token: this.getValue().ckoPaymentToken as any,
      },
      device_session_id: this.getValue().riskJsDeviceSessionId || null,
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public getGooglePayCheckoutLtdPaymentDetails(
    queryParams?: string | null
  ): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    // push express_checkout flag to the query params for the success purchase event
    if (this.getValue().isExpressCheckoutOrder === true) {
      queryParams = queryParams
        ? `${queryParams}&express_checkout=true`
        : '?express_checkout=true';
      this.store.setIsExpressCheckoutOrder(false);
    }

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    const parsedGooglePayToken = JSON.parse(this.getValue().ckoPaymentToken || '{}');

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_hash: null,
      card_token: null,
      wallet: {
        google_pay_token: {
          protocol_version: parsedGooglePayToken.protocolVersion,
          signature: parsedGooglePayToken.signature,
          signed_message: parsedGooglePayToken.signedMessage,
        },
      },
      device_session_id: this.getValue().riskJsDeviceSessionId || null,
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public getSTCPayCheckoutLtdPaymentDetails(
    queryParams?: string | null
  ): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/cko-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_hash: null,
      card_token: null,
      wallet: null,
      device_session_id: this.getValue().riskJsDeviceSessionId || null,
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public getSTCPayPaymentDetails(queryParams?: string | null): PaymentRequestInfo {
    const host = getCurrentHost();
    const localeFragment = this.akitaRouterQuery.localeFragmentToString;
    let successUrl = `${host}${localeFragment}/checkout/${this.sessionId}/stc-success`;
    let failedUrl = `${host}${localeFragment}/checkout/${this.sessionId}/stc-failure`;
    const userId = this.akitaAuthQuery.getValue().user?.id || null;

    if (queryParams) {
      successUrl = `${successUrl}?${queryParams}`;
      failedUrl = `${failedUrl}?${queryParams}`;
    }

    return {
      session_id: this.sessionId || '',
      customer_id: userId, // if user is logged
      email: this.getValue().session?.email || '', // mandatory
      client: 'WEB',
      card_holder: {
        customer_details: {
          name: this.session?.userName || '',
          contact: {
            phone_area_code: this.session?.phone?.areaCode || '',
            phone_number: this.session?.phone?.national || '',
            phone_country_code: this.session?.phone?.countryCode || '',
          },
        },
        tax_document_type: null,
        tax_document_number: '',
        birthdate: '',
      },
      post_action_links: {
        on_success: successUrl,
        on_fail: failedUrl,
      },
    };
  }

  public generatePaymentDetails(queryParams?: string | null): PaymentRequestInfo | null {
    switch (this.session?.paymentProvider) {
      case 'TABBY':
        return this.getTabbyPaymentDetails(queryParams);
      case 'TAMARA':
        return this.getTamaraPaymentDetails(queryParams);
      case 'STRIPE_V2':
        return this.getStripeV2PaymentDetails();
      case 'PAYPAL':
        return this.getPayPalPaymentDetails(queryParams);
      case 'QUARA':
        return this.getQuaraPaymentDetails(queryParams);
      case 'STC_PAY':
        return this.getSTCPayPaymentDetails(queryParams);
      case 'CHECKOUT':
        switch (this.session?.paymentMethod) {
          case 'APPLE_PAY':
            return this.getApplePayCheckoutLtdPaymentDetails(queryParams);
          case 'GOOGLE_PAY':
            return this.getGooglePayCheckoutLtdPaymentDetails(queryParams);
          case 'STC_PAY':
            return this.getSTCPayCheckoutLtdPaymentDetails(queryParams);
          case 'BASEETA_CARD':
            return this.getBaseetaPaymentDetails(queryParams);
          default:
            return this.getCheckoutLtdPaymentDetails(queryParams);
        }

      default:
        return null;
    }
  }
}
