import { Injectable } from '@angular/core';
import consolere from 'console-remote-client';

const CONSOLE_REMOTE_PROJECT_NAME = 'popsy-debug';
const DEBUG_USER_EMAIL = 'mglpajaro@gmail.com';

@Injectable({
  providedIn: 'root',
})
export class ConsoleRemoteService {
  constructor() {}

  private init(): void {
    consolere.connect({
      server: 'https://console.re', // optional, default: https://console.re
      channel: CONSOLE_REMOTE_PROJECT_NAME, // required
      redirectDefaultConsoleToRemote: true, // optional, default: false
      disableDefaultConsoleOutput: false, // optional, default: false
    });
  }

  public checkDebugEmailToConnect(email: string) {
    if (email === DEBUG_USER_EMAIL) {
      console.log('Connecting to console.re');
      this.init();
      console.log('Connected to console.re');
    }
  }
}
